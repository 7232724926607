.popup-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.popup-cancel-button {
  background-color: #e55113;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  flex: 0 1 calc(50% - 15px);
}

.popup-cancel-button:hover {
  background-color: #b84300;
}

.popup-button {
  background-color: rgb(41, 121, 255);
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  flex: 0 1 calc(50% - 15px);
}

.popup-button:hover {
  background-color: rgba(41, 121, 255, 0.85);
}

.popup-actions-custom {
  gap: 12px;
}
